import * as React from "react";
import { theme } from "../theme/theme";
import { ThemeProvider } from "theme-ui";
import "../index.css";
// import { Page } from "../components/Page/Page";
import NavBar from "../components/NavBar/Navbar";
import Footer from "../components/Footer/Footer";
import { NavBarItem } from "../types";
import HomeModule from "../components/modules/HomeModule";

type IPageTemplate = {
  pageContext: {
    id: string;
    navBarItems: NavBarItem[];
  };
  data: {
    contentful: any;
  };
};

function Home({ data, pageContext }: IPageTemplate): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <NavBar
        currentPageId={pageContext.id}
        navBarItems={pageContext.navBarItems}
      />
      <HomeModule animate={true} />
      <Footer />
    </ThemeProvider>
  );
}

export default Home;